$(document).ready(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.product .main-header').outerHeight(true);
    var hBanner = hWin - hHeader;
    $('.product .group-detail').height(hBanner);
});
$(window).resize(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.product .main-header').outerHeight(true);
    var hBanner = hWin - hHeader;
    $('.product .group-detail').height(hBanner);
});
/////////////////////////////
$(document).ready(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.product-dragon .main-header').outerHeight(true);
    var hBanner = hWin - hHeader;
    $('.product-dragon .group-thuml').height(hBanner);
    ///////////////////
    var hFooter = $('.product-dragon .footer').outerHeight(true);
    var hMap = hWin - hFooter;
    $('.product-dragon .group-story').height(hMap);
});
$(window).resize(function() {
    var hWin = $(window).height();
    var wWin = $(window).width();
    var hHeader = $('.product-dragon .main-header').outerHeight(true);
    var hBanner = hWin - hHeader;
    $('.product-dragon .group-thuml').height(hBanner);
    ///////////////////
    var hFooter = $('.product-dragon .footer').outerHeight(true);
    var hMap = hWin - hFooter;
    $('.product-dragon .group-story').height(hMap);
});